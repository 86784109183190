<style type="text/css">
  @import "~@/assets/css/common.css";
</style>
<script>
  import Layout from "@/views/layouts/main";
  import PageHeader from "@/components/page-header";


  /**
   * PersonData
   */
  export default {
    components: {
      Layout,
      PageHeader,


    },
    data() {
      return {
        title: "机构用户个人资料",
        items: [{
            text: "资料完善"
          },
          {
            text: "基本信息",
            active: true
          }
        ],
       
      };
    }
  };
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card" style="min-height: 470px;">
          <div class="card-body ">
            <h4 class="card-title pb-3 border-dash"><span class="mr-2">基本资料</span>
            </h4>
            <div class="outerCotent p-4">
              <input type="hidden" class="msgValInput" value="" name="sid">
              <div class="msgContent flexList fs-xs">
                <div class="flexList w-50 msgLabel">
                  <div class="msgName">姓名</div>
                  <div class="msgVal  flexList">
                    <input class="msgValInput w-40" readOnly disabled value="王小川 ">
                  </div>
                </div>
                <div class="flexList w-50 msgLabel">
                  <div class="msgName">身份证号</div>
                  <div class="msgVal  flexList">
                    <input class="msgValInput  w-40" readOnly disabled value="510101201611222345">
                  </div>
                </div>
                <div class="flexList w-50 msgLabel">
                  <div class="msgName">性别</div>
                  <div class="msgVal  flexList">
                    <div class="frame-wrap">
                      <div class="custom-control custom-radio custom-control-inline">
                        <input type="radio" class="custom-control-input xb" id="man" name="xb" value="男">
                        <label class="custom-control-label" style="line-height:19px" for="man">男</label>
                      </div>
                      <div class="custom-control custom-radio custom-control-inline">
                        <input type="radio" class="custom-control-input xb" id="woman" name="xb" value="女">
                        <label class="custom-control-label" style="line-height:19px" for="woman">女</label>
                      </div>
                    </div>

                  </div>
                </div>
                <div class="flexList w-50 msgLabel">
                  <div class="msgName ">出生年月</div>
                  <div class="msgVal flexList">
                    <b-form-input id="month" value="2019-08" type="month" class="h35 msgValInput " >
                    </b-form-input>
                  </div>
                </div>
                <div class="flexList w-50 msgLabel">
                  <div class="msgName">机构</div>
                  <div class="msgVal selectShow flexList">
                    <select name="" class="msgValInput form-control">
                      <option value="">四川省房建局</option>
                      <option value="">四川省房建局四川省房建局</option>
                      <option value="">四川省房建局</option>
                    </select>
                    
                  </div>
                </div>

                <div class="flexList w-50 msgLabel">
                  <div class="msgName">机构代码</div>
                  <div class="msgVal selectShow flexList">
                    <select name="" class="msgValInput form-control">
                      <option value="">51001</option>
                      <option value="">四川省房建局四川省房建局</option>
                      <option value="">四川省房建局</option>
                    </select>
                    
                  </div>
                </div>
                <div class="flexList w-50 msgLabel">
                  <div class="msgName">部门</div>
                  <div class="msgVal  flexList">
                    <input class="msgValInput form-control " value="综合办">
                  </div>
                </div>

                <div class="flexList w-50 msgLabel">
                  <div class="msgName">职务</div>
                  <div class="msgVal flexList">
                    <input type="text" class="form-control  msgValInput" value="科长">
                  </div>
                </div>

                <div class="flexList w-50 msgLabel">
                  <div class="msgName">类别</div>
                  <div class="msgVal flexList">
                    <input type="text" class="form-control  msgValInput" value="机构管理员">
                  </div>
                </div>
                <div class="flexList w-50 msgLabel">
                  <div class="msgName">办公电话</div>
                  <div class="msgVal  flexList">
                   <input type="text" class="form-control  msgValInput" value="028-87652589"> 

                  </div>
                </div>
                <div class="flexList w-50 msgLabel">
                  <div class="msgName">电子邮件</div>
                  <div class="msgVal  flexList">
                   <input type="text" class="form-control  msgValInput" value="201458964@qq.com"> 

                  </div>
                </div>
                <div class="flexList w-50 msgLabel">
                  <div class="msgName">联系电话</div>
                  <div class="msgVal  flexList">
                    <input class="msgValInput  form-control  mr-2" name="lxdh" value="138013881388">
                  </div>
                </div>
                
                <div class="flexList w-50 msgLabel">
                  <div class="msgName flexList justify-content-center">办公地址</div>
                  <div class="msgVal flexList ">
                   <input type="text" class="form-control  msgValInput" value="四川省成都市青羊区SS大道143栋113号
">
                  </div>
                </div>
                <div class="flexList w-50 msgLabel">
                  <div class="msgName">是否可以</div>
                  <div class="msgVal selectShow flexList">
                    <select name="" class="msgValInput form-control">
                      <option value="">可用</option>
                      <option value="">不可用</option>
                      <option value="">四川省房建局</option>
                    </select>
                   
                  </div>
                </div>
                <div class="flexList w-100 msgLabel">
                  <div class="msgName">角色</div>
                  <div class="msgVal ">
                     <div class="line1">机构用户</div>
                  </div>
                </div>
                
                <div class="flexList w-100 msgLabel" style="height: 100px;">
                  <div class="msgName">权限</div>
                  <div class="msgVal" style="line-height: 30px;">
                   <div class="mt-2">
                     <div class="custom-control custom-radio">
                       <input type="radio" class="custom-control-input " id="value1"  value="权限2">
                       <label class="custom-control-label" style="line-height:23px" for="value1">权限1</label>
                     </div>
                     <div class="custom-control custom-radio">
                       <input type="radio" class="custom-control-input " id="value1"  value="权限2">
                       <label class="custom-control-label" style="line-height:23px" for="value1">权限2</label>
                     </div>
                     <div class="custom-control custom-radio">
                       <input type="radio" class="custom-control-input " id="value1"  value="权限3">
                       <label class="custom-control-label" style="line-height:23px" for="value1">权限3</label>
                     </div>
                   </div>
                  </div>
                </div>
                <div class="flexList w-100 msgLabel" style="height: 50px;">
                  <div class="msgVal text-center">
                    <a href="/"><button class="btn btn-info h35 sub-btn">提交信息</button></a>
                  </div>
                </div>
              </div>

            </div>


          </div>
        </div>
      </div>


    </div>

  </Layout>
</template>
